import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { getValueOrDefault } from "~/lib/shared/getValueOrDefault";
import { valueIsEnum } from "~/lib/shared/typeUtilities";
import { SearchyOrder } from "~/types/searchy/SearchyOrder";
import { OptionData } from "~/ui-library/components/Select";

type SupportedSearchParams = {
  content_type?: string | number | null;
  industry?: string | number | null;
  sort?: string | number | null;
  /** tag ID to filter on */
  topic?: string | number | null;
  query?: string | null;
};

type UseGenericSearchOptions = {
  categoryOptions: OptionData[];
  contentTypeOptions: OptionData[];
  topicOptions: OptionData[];
  onFilterUpdate: (filterString: string) => void;
  sortOptions: OptionData[];
};

const getQueryParams = (
  newValues: SupportedSearchParams,
  currentValues: SupportedSearchParams
): string => {
  const {
    content_type: contentType,
    industry,
    sort,
    topic,
    query,
  } = {
    ...currentValues,
    ...newValues,
  };

  const params = new URLSearchParams();

  if (query) {
    params.set("query", query);
  }

  if (contentType) {
    params.set("content_type", contentType.toString());
  }

  if (industry) {
    params.set("industry", industry.toString());
  }

  if (sort) {
    params.set("sort", sort.toString());
  }

  if (topic) {
    params.set("topic", topic.toString());
  }

  return params.toString();
};

export const useGenericSearch = ({
  categoryOptions,
  contentTypeOptions,
  topicOptions,
  onFilterUpdate,
  sortOptions,
}: UseGenericSearchOptions) => {
  const router = useRouter();
  const { query } = router;

  const [searchValue, setSearchValueLocal] = useState("");
  const [enableSearch, setEnableSearch] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<OptionData | null>(
    null
  );
  const [selectedContentType, setSelectedContentType] =
    useState<OptionData | null>(null);
  const [selectedSort, setSelectedSort] = useState<OptionData | null>(null);
  const [selectedTopic, setSelectedTopic] = useState<OptionData | null>(null);

  const setSearchValue = (value: string) => {
    if (value.length > 0) {
      setSelectedSort(sortOptions[0]);
    } else {
      setSelectedSort(sortOptions[1]);
    }
    setSearchValueLocal(value);
    const queryString = getQueryParams({ query: value }, query);
    onFilterUpdate(queryString);
  };

  // initialize the state based on query params
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const searchQuery = search.get("query");
    const category = search.get("industry");
    const contentType = search.get("content_type");
    const sort = search.get("sort") as SearchyOrder;
    const topic = search.get("topic");

    if (!enableSearch) {
      if (typeof searchQuery === "string") {
        setSearchValue(searchQuery);
      }
      if (valueIsEnum(sort, SearchyOrder)) {
        setSelectedSort(
          getValueOrDefault(
            sortOptions.find(({ id }) => id === sort),
            null
          )
        );
      } else {
        setSelectedSort(sortOptions[1]);
      }

      if (contentType) {
        setSelectedContentType(
          getValueOrDefault(
            contentTypeOptions.find(({ id }) => id === contentType),
            null
          )
        );

        /* An item with an empty id is appended in all uses cases in apiToGenericSearch
        This simply removes that item from the list for most straightforward logic */
      } else if (contentTypeOptions.filter(({ id }) => !!id).length === 1) {
        setSelectedContentType(contentTypeOptions[1]);
      }

      if (category) {
        setSelectedCategory(
          getValueOrDefault(
            categoryOptions.find(({ id }) => id === category),
            null
          )
        );
        /* An item with an empty id is appended in all uses cases in apiToGenericSearch
        This simply removes that item from the list for most straightforward logic */
      } else if (categoryOptions.filter(({ id }) => !!id).length === 1) {
        setSelectedCategory(categoryOptions[1]);
      }

      if (topic) {
        setSelectedTopic(
          getValueOrDefault(
            topicOptions.find(({ id }) => id.toString() === topic),
            null
          )
        );
        /* An item with an empty id is appended in all uses cases in apiToGenericSearch
        This simply removes that item from the list for most straightforward logic */
      } else if (topicOptions.filter(({ id }) => !!id).length === 1) {
        setSelectedTopic(topicOptions[0]);
      }

      setEnableSearch(true);
    }
  }, [enableSearch, categoryOptions, topicOptions, contentTypeOptions]);

  const onCategorySelect = (item: OptionData | null) => {
    setSelectedCategory(item);
    const queryString = getQueryParams({ industry: item?.id }, query);
    onFilterUpdate(queryString);
  };

  const onContentTypeSelect = (item: OptionData | null) => {
    setSelectedContentType(item);
    const queryString = getQueryParams({ content_type: item?.id }, query);

    onFilterUpdate(queryString);
  };
  const onSortSelect = (item: OptionData) => {
    setSelectedSort(item);
    const queryString = getQueryParams({ sort: item.id }, query);
    onFilterUpdate(queryString);
  };

  const onTopicSelect = (item: OptionData | null) => {
    setSelectedTopic(item);
    const queryString = getQueryParams({ topic: item?.id }, query);
    onFilterUpdate(queryString);
  };

  return {
    enableSearch,
    searchValue,
    setSearchValue,
    selectedCategory,
    selectedContentType,
    selectedSort,
    selectedTopic,
    onCategorySelect,
    onContentTypeSelect,
    onSortSelect,
    onTopicSelect,
  };
};
